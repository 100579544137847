'use client';

import React from 'react';
import { useTranslations } from 'next-intl';
import { buildRoute } from '@headless-workspace/config';
import { useAppLocale } from '@headless-workspace/domain/common/client';
import { ErrorPageContent } from '../errors/ErrorPageContent';
import { ErrorPageProps } from '../errors/types';

const errorTitle = 'Home Page Error';

export default function ErrorPage(props: ErrorPageProps) {
    const t = useTranslations('Common.server.error');
    const locale = useAppLocale();

    return (
        <ErrorPageContent
            {...props}
            title={errorTitle}
            actionLabel={t('tryAgain')}
            fallbackUrl={buildRoute(locale, 'help')}
        />
    );
}
